<template>
  <div class="container-outer" :class="{ 'mobile-activated': !showMobileOverview, 'thank-you': currentStep === 5 }">
    <section id="wizardsteps">
      <ul>
        <li class="first-step" :class="{ 'first-step-active': currentStep === 0, 'step-done': currentStep > 0 }">Soort transport</li>
        <li class="second-step" :class="{ 'second-step-active': currentStep === 1, 'step-done': currentStep > 1 }">Je item(s)</li>
        <li class="third-step" :class="{ 'third-step-active': currentStep === 2, 'step-done': currentStep > 2 }">Datum en tijd</li>
        <li class="fourth-step" :class="{ 'fourth-step-active': currentStep === 3, 'step-done': currentStep > 3 }">Extra diensten</li>
        <li class="fifth-step" :class="{ 'fifth-step-active': currentStep === 4, 'step-done': currentStep > 4 }">Reserveren</li>
        <li class="sixth-step" :class="{ 'sixth-step-active': currentStep === 5, 'step-done': currentStep > 5 }">Bedankt</li>
      </ul>
      <div class="cost-overview-mobile" @click="showMobileOverview = false">
				<span>&euro; {{ formatPrice(this.order.priceTotal) }}</span>
				<button class="collapse-btn"></button>
      </div>
    </section>

    <aside id="mainaside">
      <div class="aside-inner">
        <h2 class="aside-title">Kostenoverzicht</h2>
        <button class="close-btn" @click="showMobileOverview = true"></button>
        <div class="services-overview">
          <ul>
            <li @click="showCardDetails[0] = !showCardDetails[0]" :class="{ 'open-card': showCardDetails[0] }">
              <transition name="flash" mode="out-in">
                <div :key="'travel-' + priceTravel">
                  <h4 class="item-title">Transportkosten</h4>
                  <h5 class="item-description" v-if="order.pickupCity && order.deliveryCity">{{ order.pickupCity }} naar {{ order.deliveryCity }}</h5>
                  <span class="price">&euro; {{ formatPrice(42.5 + priceTravel) }}</span>
                </div>
              </transition>
              <button class="collapse-btn"></button>
              <div class="cost-breakdown">
								<div class="flex-outer">
									<span>Basistarief</span>
									<span class="price-detail">&euro; 42,50</span>
								</div>
								<div class="flex-outer" v-if="priceTravel > 0">
									<span>Reiskosten {{ order.distance }} km</span>
									<span class="price-detail">&euro; {{ formatPrice(priceTravel) }}</span>
								</div>
              </div>
            </li>
            <li v-if="itemsCount">
              <transition name="flash" mode="out-in">
                <div :key="'items-' + priceItems">
                  <h4 class="item-title" :key="'items-' + priceItems">Te vervoeren {{ itemsCount === 1 ? 'item' : 'items' }}</h4>
                  <h5 class="item-description">Vervoer van {{ itemsCount }} {{ itemsCount === 1 ? 'item' : 'items' }}</h5>
                  <span class="price">&euro; {{ formatPrice(priceItems) }}</span>
                </div>
              </transition>
            </li>
            <li @click="showCardDetails[2] = !showCardDetails[2]" :class="{ 'open-card': showCardDetails[2] }" v-if="order.date">
              <transition name="flash" mode="out-in">
                <div :key="'date-' + (priceTime + priceDate)">
                  <h4 class="item-title">Datum en tijd</h4>
                  <h5 class="item-description" v-if="order.date">Transport op {{ new Date(order.date).format('D MMMM').toLowerCase() }}</h5>
                  <span class="price">&euro; {{ formatPrice(priceTime + priceDate) }}</span>
                </div>
              </transition>
              <button class="collapse-btn"></button>
              <div class="cost-breakdown" v-if="priceDate > 0 || order.pickupTime || order.deliveryTime">
                <div class="flex-outer" v-if="priceDate > 0">
                  <span>Toeslag dag/datum</span>
                  <span class="price-detail">&euro; {{ formatPrice(priceDate) }}</span>
                </div>
                <div class="flex-outer" v-if="order.pickupTime">
                  <span>Ophalen {{ order.pickupTime === 'all day' ? 'gehele dag' : formatCustomTimePeriod(order.pickupTimeCustom, 'pickup', true) }}</span>
                  <span class="price-detail">&euro; {{ order.pickupTime === 'all day' ? '0,00' : '12,50' }}</span>
                </div>
                <div class="flex-outer" v-if="order.deliveryTime">
                  <span>Bezorgen {{ order.deliveryTime === 'all day' ? 'gehele dag' : formatCustomTimePeriod(order.deliveryTimeCustom, 'delivery', true) }}</span>
                  <span class="price-detail">&euro; {{ order.deliveryTime === 'all day' ? '0,00' : '12,50' }}</span>
                </div>
              </div>
            </li>
            <li @click="showCardDetails[3] = !showCardDetails[3]" :class="{ 'open-card': showCardDetails[3] }" v-if="currentStep > 1 && order.deliveryType">
              <transition name="flash" mode="out-in">
                <div :key="'extra' + (priceExtra + priceLocation)">
                  <h4 class="item-title">Extra diensten</h4>
                  <h5 class="item-description">{{ order.deliveryType === 'regular' ? 'Deur-tot-deur transport' : 'Zelf samengesteld transport' }}</h5>
                  <span class="price">&euro; {{ formatPrice(priceExtra + priceLocation) }}</span>
                </div>
              </transition>
              <button class="collapse-btn"></button>
              <div class="cost-breakdown">
                <div class="flex-outer" v-if="priceLocation === 0">
                  <span>Ophalen/bezorgen begane grond</span>
                  <span class="price-detail">&euro; {{ formatPrice(priceLocation) }}</span>
                </div>
                <div class="flex-outer" v-if="priceLocation > 0">
                  <span>Ophalen/bezorgen op verdieping</span>
                  <span class="price-detail">&euro; {{ formatPrice(priceLocation) }}</span>
                </div>
                <div class="flex-outer" v-if="order.help">
                  <span>Extra sjouwhulp</span>
                  <span class="price-detail">&euro; {{ formatPrice(priceExtra) }}</span>
                </div>
              </div>
            </li>
            <li class="coupon" v-if="order.discountApplied">
              <transition name="flash" mode="out-in">
                <div>
                  <h4 class="item-title">10% korting</h4>
                  <span class="price">-&euro; {{ formatPrice(priceDiscount) }}</span>
                </div>
              </transition>
            </li>
            <li class="coupon" v-if="order.coupon.id">
							<transition name="flash" mode="out-in">
                <div>
                  <h4 class="item-title">Couponcode</h4>
                  <h5 class="item-description">Coupon {{ order.coupon.code }}: {{ order.coupon.discount.includes('%') ? order.coupon.discount : order.coupon.discount + ' euro' }} korting</h5>
                  <span class="price">-&euro; {{ formatPrice(priceCoupon) }}</span>
                </div>
              </transition>
            </li>
          </ul>
        </div>
        <div class="nudge nudge-discount d-none d-lg-block" v-if="discountActive && this.order.priceTotal > 0">
					<h3 class="nudge-title">Ontvang 10% korting</h3>
					<span class="discount-text">Van €{{ formatPrice(this.order.priceTotal)}} voor <i>€{{ formatPrice(this.order.priceTotal - (this.order.priceTotal * 0.1)) }}</i></span>
					<div class="buttons-side-to-side">
						<button class="btn btn-tertiary" @click="discountActive = false">Afwijzen</button>
						<button class="btn btn-primary" @click="discountActive = false; order.discountApplied = true">Toepassen</button>
					</div>
				</div>
        <div class="totals">
          <div class="flex-outer">
            <span>Subtotaal</span>
            <span>&euro; {{ formatPrice(this.order.priceSubtotal) }}</span>
          </div>
          <div class="flex-outer">
            <span>21% BTW</span>
            <span>&euro; {{ formatPrice(this.order.priceVAT) }}</span>
          </div>
          <div class="flex-outer">
            <span>Totaal</span>
            <span class="total-final">&euro; {{ formatPrice(this.order.priceTotal) }}</span>
          </div>
          <div class="flex-outer">
            <span>
              <img src="~@/assets/img/logo-klarna.svg" alt="Logo Direct Transport" height="25"> Betaal achteraf met Klarna
            </span>
          </div>
        </div>
      </div>
    </aside>

    <main id="maincontent" v-if="currentStep !== $">

      <section class="maincontent-no-btn step1" v-show="currentStep === 0">
        <h2 class="maintitle first-title">Soort transport</h2>
        <h4 class="special-title">Wat voor transport wilt u laten verrichten?</h4>
        <div class="content-container">
          <div class="form-check big-label big-label-edited">
            <input type="radio" id="sort-marktplaats" name="kind" value="Marktplaats" class="form-check-input" v-model="order.kind">
            <label for="sort-marktplaats" class="form-check-label">
              <div class="inner-label">
                <span class="time">Marktplaats</span>
                <span class="price">
                  <img src="~@/assets/img/icon-marktplaats.svg" width="25" height="24" alt="Marktplaats" v-if="order.kind !== 'Marktplaats'">
                  <img src="~@/assets/img/icon-marktplaats-active.svg" width="25" height="24" alt="Marktplaats" v-if="order.kind === 'Marktplaats'">
                </span>
              </div>
            </label>
          </div>
          <div class="form-check big-label big-label-edited">
            <input type="radio" id="sort-winkel" name="kind" value="store" class="form-check-input" v-model="order.kind">
            <label for="sort-winkel" class="form-check-label">
              <div class="inner-label">
                <span class="time">Winkel/bedrijf</span>
                <span class="price">
                  <img src="~@/assets/img/icon-stall.svg" width="25" height="24" alt="stall" v-if="order.kind !== 'store'">
                  <img src="~@/assets/img/icon-stall-active.svg" width="25" height="24" alt="stall" v-if="order.kind === 'store'">
                </span>
              </div>
            </label>
          </div>
          <div class="form-check big-label big-label-edited">
            <input type="radio" id="sort-veiling" name="kind" value="auction house" class="form-check-input" v-model="order.kind">
            <label for="sort-veiling" class="form-check-label">
              <div class="inner-label">
                <span class="time">Veiling</span>
                <span class="price">
                  <img src="~@/assets/img/icon-hammer.svg" width="25" height="24" alt="hammer" v-if="order.kind !== 'auction house'">
                  <img src="~@/assets/img/icon-hammer-active.svg" width="25" height="24" alt="hammer" v-if="order.kind === 'auction house'">
                </span>
              </div>
            </label>
          </div>
          <div class="form-check big-label big-label-edited">
            <input type="radio" id="sort-bekende" name="kind" value="friend" class="form-check-input" v-model="order.kind">
            <label for="sort-bekende" class="form-check-label">
              <div class="inner-label">
                <span class="time">Bekende</span>
                <span class="price">
                  <img src="~@/assets/img/icon-person.svg" width="25" height="24" alt="person" v-if="order.kind !== 'friend'">
                  <img src="~@/assets/img/icon-person-active.svg" width="25" height="24" alt="person" v-if="order.kind === 'friend'">
                </span>
              </div>
            </label>
          </div>
          <div class="form-check big-label big-label-edited">
            <input type="radio" id="sort-anders" name="kind" value="other" class="form-check-input" v-model="order.kind">
            <label for="sort-anders" class="form-check-label">
              <div class="inner-label">
                <span class="time">Anders</span>
                <span class="price"></span>
              </div>
            </label>
          </div>

          <!-- alert general -->

          <div role="alert" class="alert alert-error alert-icon fade show alert-margin-top" v-if="alertGeneral">
            <div class="icon">
              <img src="~@/assets/img/icon-alert.svg" alt="alert">
            </div>
            <p>Het is op dit moment helaas niet mogelijk om een transport te boeken. Excuses voor het ongemak.</p>
          </div>

        </div>
      </section>

      <section class="maincontent-no-btn step2" v-show="currentStep === 1">
        <h2 class="maintitle second-title">Je item(s)</h2>
        <p class="intro-paragraph">
          Geef de items op die vervoerd moeten worden. Maak gebruik van de voorgedefinieerde items.<br>
          Wil je meer dan 10 objecten laten transporteren? Kies dan voor onze <a href="https://goto.directtransport.nl/verhuisservice">verhuisservice</a>.
        </p>
				<div class="content-container">
					<h4 class="content-title">Te vervoeren {{ itemsCount === 1 ? 'item' : 'items' }}</h4>

					<div class="labels d-none d-lg-inline-block" v-if="order.items.length">
						<label class="first-label">Item</label>
						<label class="second-label">Lengte</label>
						<label class="third-label">Breedte</label>
						<label class="fourth-label">Hoogte</label>
						<label class="fifth-label">Gewicht</label>
						<label class="sixth-label">Aantal</label>
					</div>

					<!-- items -->

					<div class="item" :class="{ 'first-item': index === 0 }" v-for="(item, index) in order.items" :key="index">
						<div class="form-group first-group" tabindex="0" @focusout="toggleDropdown[index] = false">
							<label class="d-lg-none">Item</label>
							<div class="form-control special-dropdown" :class="{ 'chosen-different': item.type === 'other' }" @click="toggleDropdown[index] = !toggleDropdown[index]">
                <span v-if="!item.type" class="select">Selecteer...</span>
								<span :class="[ itemType.class, item.type !== itemType.value ? 'hide' : '' ]" v-for="itemType in itemTypes" :key="itemType.value">{{ itemType.label }}</span>
							</div>
              <div class="form-control special-dropdown dropdown" :class="{ 'activedropdown': toggleDropdown[index] }">
                <span :class="itemType.class" v-for="itemType in itemTypes" :key="itemType.value" @click="setItem(item, itemType); toggleDropdown[index] = false;">{{ itemType.label }}</span>
              </div>
              <div class="chosen-different-container" v-if="item.type === 'other'">
                <input type="text" id="different-field" class="form-control" placeholder="Namelijk..." autocomplete="off" v-model="item.typeOther" required>
              </div>
						</div>
						<div class="form-group second-group">
							<label :for="'item-length' + index + '-field'" class="d-lg-none">Lengte</label>
							<input type="text" :id="'item-length' + index + '-field'" class="form-control" :class="{ 'val-error': item.lengthError }" placeholder="in cm" autocomplete="off" v-model="item.length" @change="formatSize(index, 'length')" required>
						</div>
						<div class="form-group third-group">
							<label :for="'item-width' + index + '-field'" class="d-lg-none">Breedte</label>
							<input type="text" :id="'item-width' + index + '-field'" class="form-control" :class="{ 'val-error': item.widthError }" placeholder="in cm" autocomplete="off" v-model="item.width" @change="formatSize(index, 'width')" required>
						</div>
						<div class="form-group fourth-group">
							<label :for="'item-height' + index + '-field'" class="d-lg-none">Hoogte</label>
							<input type="text" :id="'item-height' + index + '-field'" class="form-control" :class="{ 'val-error': item.heightError }" placeholder="in cm" autocomplete="off" v-model="item.height" @change="formatSize(index, 'height')" required>
						</div>
						<div class="form-group fifth-group">
							<label :for="'item-weight-class' + index + '-field'" class="d-lg-none">Gewicht</label>
							<select class="form-control" :id="'item-weight-class' + index + '-field'" v-model="item.weightClass">
								<option value="0-20">0	&#8211; 20 kg</option>
								<option value="20-80">20 &#8211; 80 kg</option>
                <option value="80-100">80 &#8211; 100 kg</option>
                <option value="100+">100+ kg</option>
							</select>
						</div>
						<div class="form-group sixth-group number-counter-group">
							<label :for="'item-amount' + index + '-field'" class="d-lg-none">Aantal</label>
							<div class="input-group-prepend">
								<button type="button" class="btn counter-min" @click="item.amount !== 1 ? item.amount-- : 1"></button>
							</div>
							<input type="number" :id="'item-amount' + index + '-field'" min="1" class="text-center counter-value form-control" v-model="item.amount">
							<div class="input-group-append">
								<button type="button" class="btn counter-plus" @click="item.amount++"></button>
							</div>
						</div>
            <button role="button" class="btn-delete" @click="order.items.splice(index, 1)" v-if="index !== 0"></button>
					</div>

          <!-- alert volume -->

          <div role="alert" class="alert alert-error alert-icon fade show alert-margin-top" v-if="alertVolume">
            <div class="icon">
              <img src="~@/assets/img/icon-alert.svg" alt="alert">
            </div>
            <p>Onze maximale vervoerscapaciteit per transport is 6 m3. Helaas kunnen wij dit niet uitvoeren.</p>
          </div>

          <!-- alert weight -->

          <div role="alert" class="alert alert-error alert-icon fade show alert-margin-top" v-if="alertWeight">
            <div class="icon">
              <img src="~@/assets/img/icon-alert.svg" alt="alert">
            </div>
            <p>Items mogen maximaal 100 kg per stuk zijn. Helaas kunnen wij dit niet uitvoeren.</p>
          </div>

          <!-- alert size -->

          <div role="alert" class="alert alert-error alert-icon fade show alert-margin-top" v-if="alertSize">
            <div class="icon">
              <img src="~@/assets/img/icon-alert.svg" alt="alert">
            </div>
            <p>
              De afmetingen van onze bussen zijn 400x200x220 cm. Helaas kunnen wij dit niet uitvoeren.<br>
              Bestaat je item uit meerdere delen? Vul dan per onderdeel de afmetingen in.
            </p>
          </div>

          <!-- alert amount -->

          <div role="alert" class="alert alert-error alert-icon fade show alert-margin-top" v-if="itemsCount > 10">
            <div class="icon">
              <img src="~@/assets/img/icon-alert.svg" alt="alert">
            </div>
            <p>Je kunt maximaal 10 items opgeven. Helaas kunnen wij dit niet uitvoeren.</p>
          </div>

          <!-- add item -->

					<button type="button" class="btn btn-secondary btn-icon" @click="addItem" v-if="itemsCount <= 10">
						<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0.5V6.5M6 12.5V6.5M6 6.5H0H12" stroke="white" stroke-width="1.5"/></svg>Voeg een item toe
					</button>
				</div>
      </section>

      <section class="maincontent-no-btn step-3" v-show="currentStep === 2">
        <h2 class="maintitle third-title">Datum en tijd</h2>
        <p class="intro-paragraph">
          Selecteer een datum en tijdframe voor het ophalen en afleveren. 24 uur van tevoren ontvang je een exacter bezorgmoment.<br>
          Wil je een spoedtransport boeken? Bel dan <a href="https://goto.directtransport.nl/spoedtransport">085 130 1832</a> voor onze spoeddienst!
        </p>
        <div class="content-container">
					<div class="columns-outer">
						<div class="column first-column">
							<h4 class="content-title-small">Datum ophalen en afleveren</h4>
              <vue-cal
                  class="vuecal--date-picker"
                  hide-view-selector
                  :time="false"
                  active-view="month"
                  :min-date="new Date().getHours() >= 12 ? new Date().addDays(2) : new Date().addDays(1)"
                  locale="nl"
                  :disable-views="['years', 'year', 'week', 'day']"
                  :disable-days="[new Date().getFullYear() + '-12-25', new Date().getFullYear() + '-12-26', new Date().getFullYear() + '-12-27', new Date().getFullYear() + '-12-31', (new Date().getFullYear() + 1) + '-01-01', (new Date().getFullYear() + 1) + '-01-02']"
                  style="height: 400px;"
                  @cell-click="dateSelected($event)"
                  @click="dateClicked($event)"
                  small>
                <template v-slot:weekday-heading="{ heading }">{{ heading.label.substring(0, 2) }}</template>
              </vue-cal>
						</div>

            <!-- pickupTime -->

						<div id="pickup-time" class="column second-column">
							<h4 class="content-title-small">Ophalen tijdstip</h4>
							<div class="form-check big-label empty-label" v-if="!order.date">
								<h5 class="empty-title">Selecteer eerst een datum</h5>
							</div>
							<div v-if="order.date">
								<div class="form-check big-label">
									<input type="radio" id="pickup-time-all-day-radio" name="pickupTime" value="all day" class="form-check-input" @click="order.deliveryTime = ''; order.deliveryTimeCustom = '16:00'" v-model="order.pickupTime">
									<label for="pickup-time-all-day-radio" class="form-check-label">
										<div class="inner-label">
											<span class="part-of-day">Gehele dag</span>
											<span class="time">Tussen 08:00 – 20:00</span>
											<span class="price">&euro; 0</span>
										</div>
									</label>
								</div>
								<div class="form-check big-label" v-if="order.distance < 150">
									<input type="radio" id="pickup-time-custom-radio" name="pickupTime" value="custom" class="form-check-input" @click="order.deliveryTime = ''" v-model="order.pickupTime">
									<label for="pickup-time-custom-radio" class="form-check-label">
										<div class="inner-label">
											<span class="part-of-day">Aangepast</span>
											<span class="time">Zelf een tijd kiezen</span>
											<span class="price">&euro; 12,50</span>
										</div>
									</label>
								</div>
                <template v-if="order.pickupTime === 'custom'">
                  <label for="pickup-time-select" class="d-lg-none">Gewicht</label>
                  <select class="form-control" id="pickup-time-select" v-model="order.pickupTimeCustom">
                    <option value="08:00">08:00 - 12:00</option>
                    <option value="09:00">09:00 - 13:00</option>
                    <option value="10:00">10:00 - 14:00</option>
                    <option value="11:00">11:00 - 15:00</option>
                    <option value="12:00">12:00 - 16:00</option>
                    <option value="13:00">13:00 - 17:00</option>
                    <option value="14:00">14:00 - 18:00</option>
                    <option value="15:00">15:00 - 19:00</option>
                    <option value="16:00">16:00 - 20:00</option>
                  </select>
                </template>
							</div>
						</div>

            <!-- deliveryTime -->

						<div class="column third-column">
							<h4 class="content-title-small">Afleveren tijdstip</h4>
							<div class="form-check big-label empty-label" v-if="!order.pickupTime">
								<h5 class="empty-title">Selecteer eerst een ophaaltijd</h5>
							</div>
							<div v-if="order.pickupTime">
								<div class="form-check big-label" v-if="order.pickupTime === 'all day' || order.pickupTimeCustom <= '12:00'">
									<input type="radio" id="delivery-time-all-day-radio" name="deliveryTime" value="all day" class="form-check-input" v-model="order.deliveryTime">
									<label for="delivery-time-all-day-radio" class="form-check-label">
										<div class="inner-label">
											<span class="part-of-day">Gehele dag</span>
											<span class="time">Tussen 08:00 – 20:00</span>
											<span class="price">&euro; 0</span>
										</div>
									</label>
								</div>
                <div class="form-check big-label" v-if="order.distance < 150">
                  <input type="radio" id="delivery-time-custom-radio" name="deliveryTime" value="custom" class="form-check-input" v-model="order.deliveryTime">
                  <label for="delivery-time-custom-radio" class="form-check-label">
                    <div class="inner-label">
                      <span class="part-of-day">Aangepast</span>
                      <span class="time">Zelf een tijd kiezen</span>
                      <span class="price">&euro; 12,50</span>
                    </div>
                  </label>
                </div>
                <template v-if="order.deliveryTime === 'custom'">
                  <label for="pickup-time-select" class="d-lg-none">Gewicht</label>
                  <select class="form-control" id="pickup-time-select" v-model="order.deliveryTimeCustom">
                    <option v-for="deliveryTimeOption in deliveryTimeOptions" :key="deliveryTimeOption.value" :value="deliveryTimeOption.value">{{ deliveryTimeOption.label }} uur</option>
                  </select>
                </template>
              </div>
						</div>
					</div>
				</div>
      </section>

      <section class="maincontent-no-btn step-3" v-show="currentStep === 3">
        <h2 class="maintitle fourth-title">Extra diensten</h2>
        <p class="intro-paragraph">Kies het deur-tot-deur pakket of stel het transport zelf af op je voorkeuren.</p>
        <div class="content-container">

          <!-- delivery type -->

					<h4 class="step first-subtitle">Kies de gewenste dienst</h4>
					<div class="columns-outer">
						<div class="column split-column">
							<div class="form-check big-label biggest-label">
								<input type="radio" id="delivery-regular-radio" name="delivery" value="regular" class="form-check-input" v-model="order.deliveryType">
								<label for="delivery-regular-radio" class="form-check-label">
									<div class="inner-label">
										<span class="radio-title">Deur-tot-deur</span>
										<span class="radio-description">Ophalen en afleveren tot de voordeur/begane grond</span>
										<span class="price">&euro; 0</span>
									</div>
								</label>
							</div>
						</div>
						<div class="column split-column">
							<div class="form-check big-label biggest-label last-label">
								<input type="radio" id="delivery-custom-radio" name="delivery" value="custom" class="form-check-input" v-model="order.deliveryType">
								<label for="delivery-custom-radio" class="form-check-label">
									<div class="inner-label">
										<span class="radio-title">Stel zelf samen</span>
										<span class="radio-description">Ophalen en afleveren tot de voordeur op een bepaalde verdieping</span>
										<span class="price">Bereken kosten</span>
									</div>
								</label>
							</div>
						</div>
					</div>

          <!-- pickup -->

					<div id="pickup" class="content-container-inner" v-if="order.deliveryType === 'custom' && validDeliveryType">
						<h4 class="step second-subtitle">
              <div class="step-inner-text" v-if="order.pickupCity">
                Ophalen in <span>{{ order.pickupCity }}</span>
              </div>
              <div class="step-inner-text" v-if="!order.pickupCity">
                Ophalen
              </div>
						</h4>
						<div class="form-group">
              <label for="pickup-building-type-field" class="label-required">Om wat voor type gebouw gaat het?</label>
              <select class="form-control" id="pickup-building-type-field" v-model="order.pickupBuildingType">
                <option value="" disabled>Kies...</option>
                <option value="house">Woning</option>
                <option value="store">Winkel</option>
                <option value="office">Kantoor</option>
                <option value="storage">Opslag</option>
                <option value="auction house">Veilinghuis</option>
              </select>
            </div>
            <div class="form-group">
              <label for="pickup-floor-field" class="label-required">Op welke verdieping moeten we de items ophalen?</label>
              <select class="form-control" id="pickup-floor-field" @change="order.pickupElevator = (validPickup && order.pickupFloor !== 'ground floor' ? (order.pickupElevator === null ? true : order.pickupElevator) : null)" v-model="order.pickupFloor">
                <option value="" disabled>Kies...</option>
                <option value="ground floor">Begane grond</option>
                <option value="first floor">1e verdieping</option>
                <option value="second floor">2e verdieping</option>
                <option value="third floor">3e verdieping</option>
                <option value="fourth floor">4e verdieping</option>
                <option value="fifth floor and up">5e verdieping of hoger</option>
              </select>
            </div>
            <div class="form-group normal-radio-group" v-if="order.pickupFloor && order.pickupFloor !== 'ground floor'">
              <label class="label-required">Is er een bruikbare lift aanwezig?</label>
              <div class="info-button" :class="{ hovering: isHoveringInfoIcon[1] }">
                <button class="info-icon" @mouseover="isHoveringInfoIcon[1] = true" @mouseout="isHoveringInfoIcon[1] = false"></button>
                <div class="info-button-text">
                  <p>
                    Vul 'Ja' in als er een interne lift in het gebouw aanwezig is die de transporteur kan gebruiken om de items omhoog te vervoeren. Controleer ook of de items in de lift passen.<br>
                    Vul 'Nee' in als er geen bruikbare lift aanwezig is.
                  </p>
                </div>
              </div>
              <div class="form-check-outer">
                <div class="form-check">
                  <input type="radio" id="pickup-elevator-true-radio" name="pickupElevator" :value="true" class="form-check-input" v-model="order.pickupElevator">
                  <label for="pickup-elevator-true-radio" class="form-check-label">
                    Ja
                  </label>
                </div>
                <div class="form-check">
                  <input type="radio" id="pickup-elevator-false-radio" name="pickupElevator" :value="false" class="form-check-input" v-model="order.pickupElevator">
                  <label for="pickup-elevator-false-radio" class="form-check-label">
                    Nee
                  </label>
                </div>
              </div>
            </div>
            <div role="alert" class="alert alert-error alert-icon fade show alert-margin-top" v-if="order.pickupElevator === false && order.pickupFloor === 'fifth floor and up'">
              <div class="icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.46756 12.9528C9.62516 12.8475 9.81044 12.7913 9.99998 12.7913C10.2541 12.7913 10.4979 12.8923 10.6776 13.072C10.8573 13.2517 10.9583 13.4955 10.9583 13.7497C10.9583 13.9392 10.9021 14.1245 10.7968 14.2821C10.6915 14.4397 10.5418 14.5625 10.3667 14.6351C10.1916 14.7076 9.99892 14.7266 9.81302 14.6896C9.62712 14.6526 9.45636 14.5613 9.32234 14.4273C9.18831 14.2933 9.09704 14.1225 9.06006 13.9366C9.02308 13.7507 9.04206 13.5581 9.1146 13.3829C9.18713 13.2078 9.30996 13.0582 9.46756 12.9528Z" fill="#FF2B44"/>
                  <path d="M10.8333 5.29134H9.16667V11.1247H10.8333V5.29134Z" fill="#FF2B44"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 1.38332C5.24114 1.38332 1.38332 5.24114 1.38332 10C1.38332 14.7589 5.24114 18.6167 10 18.6167C14.7589 18.6167 18.6167 14.7589 18.6167 10C18.6167 5.24114 14.7589 1.38332 10 1.38332Z" fill="#FF2B44"/>
                </svg>
              </div>
              <p>Conform de ARBO-wetgeving vindt bezorging zonder bruikbare lift plaats t/m de 4de verdieping. Neem contact op via 085 130 1832 of via de chat.</p>
            </div>
					</div>

          <!-- delivery -->

					<div id="delivery" class="content-container-inner" v-if="order.deliveryType === 'custom' && validPickup">
						<h4 class="step third-subtitle">
							<div class="step-inner-text" v-if="order.deliveryCity">
                Bezorgen in <span>{{ order.deliveryCity }}</span>
							</div>
              <div class="step-inner-text" v-if="!order.deliveryCity">
                Bezorgen
              </div>
						</h4>
						<div class="form-group">
              <label for="delivery-building-type-field" class="label-required">Om wat voor type gebouw gaat het?</label>
              <select class="form-control" id="delivery-building-type-field" v-model="order.deliveryBuildingType">
                <option value="" disabled>Kies...</option>
                <option value="house">Woning</option>
                <option value="store">Winkel</option>
                <option value="office">Kantoor</option>
                <option value="storage">Opslag</option>
                <option value="auction house">Veilinghuis</option>
              </select>
            </div>
            <div class="form-group">
              <label for="delivery-floor-field" class="label-required">Op welke verdieping moeten we de items bezorgen?</label>
              <select class="form-control" id="delivery-floor-field" @change="order.deliveryElevator = (validDelivery && order.deliveryFloor !== 'ground floor' ? (order.deliveryElevator === null ? true : order.deliveryElevator) : null)" v-model="order.deliveryFloor">
                <option value="" disabled>Kies...</option>
                <option value="ground floor">Begane grond</option>
                <option value="first floor">1e verdieping</option>
                <option value="second floor">2e verdieping</option>
                <option value="third floor">3e verdieping</option>
                <option value="fourth floor">4e verdieping</option>
                <option value="fifth floor and up">5e verdieping of hoger</option>
              </select>
            </div>
            <div class="form-group normal-radio-group" v-if="order.deliveryFloor && order.deliveryFloor !== 'ground floor'">
							<label class="label-required">Is er een bruikbare lift aanwezig?</label>
							<div class="info-button" :class="{ hovering: isHoveringInfoIcon[2] }">
								<button class="info-icon" @mouseover="isHoveringInfoIcon[2] = true" @mouseout="isHoveringInfoIcon[2] = false"></button>
								<div class="info-button-text">
                  <p>
                    Vul 'Ja' in als er een interne lift in het gebouw aanwezig is die de transporteur kan gebruiken om de items omhoog te vervoeren. Controleer ook of de items in de lift passen.<br>
                    Vul 'Nee' in als er geen bruikbare lift aanwezig is.
                  </p>
								</div>
							</div>
							<div class="form-check-outer">
								<div class="form-check">
									<input type="radio" id="delivery-elevator-true-radio" name="deliveryElevator" :value="true" class="form-check-input" v-model="order.deliveryElevator">
									<label for="delivery-elevator-true-radio" class="form-check-label">
										Ja
									</label>
								</div>
								<div class="form-check">
									<input type="radio" id="delivery-elevator-false-radio" name="deliveryElevator" :value="false" class="form-check-input" v-model="order.deliveryElevator">
									<label for="delivery-elevator-false-radio" class="form-check-label">
										Nee
									</label>
								</div>
							</div>
						</div>
            <div role="alert" class="alert alert-error alert-icon fade show alert-margin-top" v-if="order.deliveryElevator === false && order.deliveryFloor === 'fifth floor and up'">
							<div class="icon">
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.46756 12.9528C9.62516 12.8475 9.81044 12.7913 9.99998 12.7913C10.2541 12.7913 10.4979 12.8923 10.6776 13.072C10.8573 13.2517 10.9583 13.4955 10.9583 13.7497C10.9583 13.9392 10.9021 14.1245 10.7968 14.2821C10.6915 14.4397 10.5418 14.5625 10.3667 14.6351C10.1916 14.7076 9.99892 14.7266 9.81302 14.6896C9.62712 14.6526 9.45636 14.5613 9.32234 14.4273C9.18831 14.2933 9.09704 14.1225 9.06006 13.9366C9.02308 13.7507 9.04206 13.5581 9.1146 13.3829C9.18713 13.2078 9.30996 13.0582 9.46756 12.9528Z" fill="#FF2B44"/>
								<path d="M10.8333 5.29134H9.16667V11.1247H10.8333V5.29134Z" fill="#FF2B44"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 1.38332C5.24114 1.38332 1.38332 5.24114 1.38332 10C1.38332 14.7589 5.24114 18.6167 10 18.6167C14.7589 18.6167 18.6167 14.7589 18.6167 10C18.6167 5.24114 14.7589 1.38332 10 1.38332Z" fill="#FF2B44"/>
								</svg>
							</div>
							<p>Conform de ARBO-wetgeving vindt bezorging zonder bruikbare lift plaats t/m de 4de verdieping. Neem contact op via 085 130 1832 of via de chat.</p>
						</div>
					</div>

					<!-- help -->

					<div id="help" class="content-container-inner" v-if="order.deliveryType === 'regular' || (validPickup && validDelivery)">
						<h4 class="step" :class="[ order.deliveryType === 'custom' ? 'fourth-subtitle' : 'second-subtitle' ]">Heb je extra sjouwhulp nodig?</h4>
						<div class="columns-outer">
							<div class="column split-column">
								<div class="form-check big-label biggest-label biggest-label-desktop-smaller">
									<input type="radio" id="help-true-radio" name="help" :value="true" class="form-check-input" v-model="order.help">
									<label for="help-true-radio" class="form-check-label">
										<div class="inner-label">
											<span class="radio-title">Ja, ik wil extra sjouwhulp</span>
											<span class="radio-description">Twee koeriers zullen je helpen met tillen</span>
											<span class="price">&euro; {{ formatPrice(priceHelp) }}</span>
										</div>
									</label>
								</div>
							</div>
							<div class="column split-column">
								<div class="form-check last-label big-label biggest-label biggest-label-desktop-smaller">
									<input type="radio" id="help-false-radio" name="help" :value="false" class="form-check-input" v-model="order.help">
									<label for="help-false-radio" class="form-check-label">
										<div class="inner-label">
											<span class="radio-title">Nee, niet nodig</span>
											<span class="radio-description">Eén koerier zal je helpen met tillen</span>
											<span class="price">&euro; 0</span>
										</div>
									</label>
								</div>
							</div>
						</div>
						<div role="alert" class="alert alert-success alert-icon fade show alert-margin-top">
							<div class="icon">
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.31704 5.32866C9.47331 5.22425 9.65703 5.16852 9.84496 5.16852C10.097 5.16852 10.3387 5.26863 10.5169 5.44684C10.6951 5.62503 10.7952 5.86673 10.7952 6.11875C10.7952 6.30669 10.7395 6.49041 10.635 6.64666C10.5306 6.80293 10.3822 6.92472 10.2086 6.99664C10.035 7.06856 9.84391 7.08738 9.65958 7.05072C9.47526 7.01405 9.30594 6.92355 9.17305 6.79066C9.04016 6.65777 8.94966 6.48845 8.913 6.30413C8.87633 6.11981 8.89515 5.92875 8.96707 5.75511C9.03899 5.58148 9.16078 5.43308 9.31704 5.32866Z" fill="#25A268"/>
								<path d="M8.28317 9.91514V8.53347H10.8559V12.8442C10.8559 12.8906 10.8935 12.9282 10.9399 12.9282L12.0459 12.9291V14.3116H8.28128C8.2805 14.2742 8.27956 14.2253 8.27861 14.167C8.27635 14.027 8.27408 13.8335 8.27408 13.6199C8.27408 13.4063 8.27635 13.2128 8.27861 13.0728C8.27956 13.0146 8.2805 12.9656 8.28128 12.9282H9.38854C9.43495 12.9282 9.47257 12.8906 9.47257 12.8442V10C9.47257 9.95359 9.43495 9.91597 9.38854 9.91597L8.28317 9.91514Z" fill="#25A268"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 1.38332C5.24114 1.38332 1.38332 5.24114 1.38332 10C1.38332 14.7589 5.24114 18.6167 10 18.6167C14.7589 18.6167 18.6167 14.7589 18.6167 10C18.6167 5.24114 14.7589 1.38332 10 1.38332Z" fill="#25A268"/>
								</svg>
							</div>
							<p>Per transport gaat er standaard één koerier mee. Heeft u grote voorwerpen? Zorg dat je voldoende sjouwhulp hebt. </p>
						</div>
					</div>
        </div>
      </section>

      <section class="maincontent-no-btn step-4" v-show="currentStep === 4">
        <h2 class="maintitle fifth-title">Reserveren</h2>
        <p class="intro-paragraph">Aan de rechterkant zie je een overzicht met de totale kosten voor het opgegeven transport. Vul de gegevens aan en reserveer je transport. Op een later moment reserveren? <button data-toggle="modal" data-target="#costOverviewModal" @click="setMailData">Mail mij het kostenoverzicht</button></p>

        <!-- login/create account -->

        <div class="content-container account-options" v-if="showAccountOptions">
          <h4 class="first-unique-subtitle">Hoe wil je doorgaan?</h4>
          <div class="columns-outer">
            <div class="column split-column">
              <div class="inner-border">
                <h4 class="title">Inloggen</h4>
                <p class="intro-paragraph-unique">Vul je e-mailadres en wachtwoord in om je reservering snel af te ronden.</p>
                <div class="form-group">
                  <label>E-mailadres</label>
                  <input type="text" class="form-control" v-model="login.user">
                </div>
                <div class="form-group password">
                  <label>Wachtwoord<router-link to="/password-recover">Wachtwoord vergeten?</router-link></label>
                  <input type="password" class="form-control" v-model="login.pass" autocomplete="off">
                  <span class="error error-margin" v-if="login.error">Gebruikersnaam en/of wachtwoord onjuist</span>
                </div>
                <button type="submit" class="btn btn-primary float-right btn-login" @click="doLogin">Inloggen</button>
              </div>
            </div>
            <div class="column split-column">
              <div class="inner-border inner-border-first">
                <h4 class="title">Account aanmaken</h4>
                <p class="intro-paragraph-unique">Maak een account aan en profiteer van:</p>
                <ul class="profit-list">
                  <li>Nog sneller reserveren</li>
                  <li>Een overzicht van al je transporten</li>
                </ul>
                <button class="btn btn-primary" @click="showAccountOptions = false; createAccount = true">Account aanmaken</button>
              </div>
              <div class="inner-border">
                <h4 class="title">Doorgaan zonder account</h4>
                <p class="intro-paragraph-unique">Een account aanmaken is niet verplicht. Na het reserveren van je transport sturen we je een bevestiging per e-mail.</p>
                <button class="btn btn-primary" @click="showAccountOptions = false">Doorgaan zonder account</button>
              </div>
            </div>
          </div>
        </div>

        <!-- logged in or no account -->

        <div id="customer" class="content-container" v-if="!showAccountOptions">
					<div class="nudge nudge-price d-none " :class="{'d-flex': currentStep === 4 }" v-if="showTimeRemaining">
						<button type="button" class="close-btn" @click="showTimeRemaining = false"></button>
						<div class="image">
							<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 15C0 6.71573 6.71573 0 15 0V0C23.2843 0 30 6.71573 30 15V15C30 23.2843 23.2843 30 15 30V30C6.71573 30 0 23.2843 0 15V15Z" fill="#25A268"/>
							<path d="M20.1514 11.2053C23.0033 14.0571 23.0033 18.6809 20.1514 21.5328C17.2996 24.3846 12.6758 24.3846 9.82395 21.5328C6.9721 18.6809 6.9721 14.0571 9.82395 11.2053C12.6758 8.35344 17.2996 8.35344 20.1514 11.2053" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
							<path d="M14.9878 12.7178V16.3691" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
							<path d="M12.1987 6.32788H17.7771" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
							<path d="M22.2906 9.06656L22.8209 9.59689C22.9616 9.45624 23.0406 9.26547 23.0406 9.06656C23.0406 8.86764 22.9616 8.67688 22.8209 8.53623L22.2906 9.06656ZM24.6973 10.4126L20.9446 6.65986L19.8839 7.72052L23.6367 11.4733L24.6973 10.4126ZM19.8839 7.72052L21.7603 9.59689L22.8209 8.53623L20.9446 6.65986L19.8839 7.72052ZM21.7603 8.53623L19.6215 10.675L20.6822 11.7356L22.8209 9.59689L21.7603 8.53623Z" fill="white"/>
							</svg>
						</div>
						<div class="content">
							<h3 class="nudge-title">Deze prijs geldt nog {{ timeRemaining }}</h3>
							<span class="savings-text">Prijzen kunnen omhoog gaan, dus garandeer je reservering vandaag nog.</span>
						</div>
					</div>

					<div class="content-container-inner first-content-container-inner">

						<!-- customer -->

						<h4 class="step first-subtitle">Contactgegevens aanvrager</h4>
						<div class="form-group normal-radio-group first-radio-group">
							<div class="form-check-outer">
								<div class="form-check">
									<input type="radio" id="customer-type-private-radio" name="customerType" value="private" class="form-check-input" v-model="order.customer.type">
									<label for="customer-type-private-radio" class="form-check-label">
										Particulier transport
									</label>
								</div>
								<div class="form-check">
									<input type="radio" id="customer-type-business-radio" name="customerType" value="business" class="form-check-input" v-model="order.customer.type">
									<label for="customer-type-business-radio" class="form-check-label">
										Zakelijk transport
									</label>
								</div>
							</div>
						</div>
						<div class="columns-outer inner-columns-outer" v-if="order.customer.type === 'business'">
							<div class="column inner-column split-column">
								<div class="form-group">
									<label for="company-name-field" class="label-required">Bedrijfsnaam</label>
									<input type="text" id="company-name-field" class="form-control" placeholder="Bedrijfsnaam..." v-model="order.customer.companyName" required>
								</div>
							</div>
							<div class="column inner-column split-column">
								<div class="form-group">
									<label for="coc-number-field" class="label-required">KvK-nummer</label>
									<input type="number" id="coc-number-field" class="form-control" placeholder="KvK-nummer..." v-model="order.customer.coc" required>
								</div>
							</div>
						</div>
						<div class="columns-outer inner-columns-outer">
							<div class="column inner-column split-column">
								<div class="form-group">
									<label for="first-name-field" class="label-required">Voornaam</label>
									<input type="text" id="first-name-field" class="form-control" placeholder="Voornaam..." v-model="order.customer.firstName" required>
								</div>
							</div>
							<div class="column inner-column split-column">
								<div class="form-group">
									<label for="last-name-field" class="label-required">Achternaam</label>
									<input type="text" id="last-name-field" class="form-control" placeholder="Achternaam..." v-model="order.customer.lastName" required>
								</div>
							</div>
						</div>
						<div class="columns-outer inner-columns-outer">
							<div class="column inner-column split-column">
								<div class="form-group">
									<label for="email-field" class="label-required">E-mailadres</label>
									<input type="email" id="email-field" class="form-control" placeholder="Emailadres..."  v-model="order.customer.email" required>
								</div>
							</div>
							<div class="column inner-column split-column">
								<div class="form-group">
									<label for="phone-field" class="label-required">Telefoonnummer</label>
									<input type="text" pattern="^(\d{10})$" id="phone-field" class="form-control" placeholder="Telefoonnummer..." v-model="order.customer.phone" required>
								</div>
							</div>
						</div>
            <div class="columns-outer inner-columns-outer" v-if="!user['customerId']">
              <div class="column inner-column">
                <div class="form-check special-check simple-checks simple-checks-2">
                  <input type="checkbox" id="account-create-check" name="createAccount" class="form-check-input" :value="true" @click="order.customer.passwordCheck = ''" v-model="createAccount">
                  <label for="account-create-check" class="form-check-label">
                    Account aanmaken
                  </label>
                  <div class="info-button">
                    <button class="info-icon"></button>
                    <div class="info-button-text">
                      <p>Vul 'Ja' in als er een interne lift in het gebouw aanwezig is die de transporteur kan gebruiken om de items omhoog te vervoeren. Controleer ook of de items in de lift passen.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns-outer inner-columns-outer" v-if="createAccount">
              <div class="column inner-column split-column">
                <div class="form-group">
                  <label for="password-field" class="label-required">Wachtwoord</label>
                  <input type="password" id="password-field" class="form-control" placeholder="Wachtwoord..."  v-model="order.customer.password" autocomplete="off" required>
                </div>
              </div>
              <div class="column inner-column split-column">
                <div class="form-group">
                  <label for="password-check-field" class="label-required">Bevestig je wachtwoord</label>
                  <input type="password" id="password-check-field" class="form-control" placeholder="Bevestig je wachtwoord..." v-model="order.customer.passwordCheck" autocomplete="off" required>
                </div>
              </div>
            </div>
					</div>

					<div class="columns-outer inner-columns-outer">

            <!-- pickup address -->

            <div class="column inner-column split-column">
							<div class="content-container-inner">
								<h4 class="step second-subtitle">Ophaaladres</h4>
								<div class="columns-outer inner-columns-outer">
									<div class="column inner-column split-column">
										<div class="form-group">
											<label for="pickup-postal-code-field" class="label-required">Postcode</label>
											<input type="text" id="pickup-postal-code-field" class="form-control" :class="{ 'form-control-error': validAddress['pickup'] === false }" placeholder="Postcode..." v-model="order.pickupPostalCode" @change="setAddress('pickup')" required>
										</div>
									</div>
									<div class="column inner-column split-column double-split">
										<div class="form-group first-form-group">
											<label for="pickup-house-number-field" class="label-required">Huisnr.</label>
											<input type="text" id="pickup-house-number-field" class="form-control" :class="{ 'form-control-error': validAddress['pickup'] === false }" placeholder="Nr..." v-model="order.pickupHouseNumber" @change="setAddress('pickup')" required>
										</div>
										<div class="form-group second-form-group">
											<label for="pickup-house-number-addition-field">Toev.</label>
											<input type="text" id="pickup-house-number-addition-field" class="form-control" placeholder="Toev..." v-model="order.pickupHouseNumberAddition" @change="setAddress('pickup')">
										</div>
									</div>
								</div>
								<div class="result" v-show="order.pickupAddress">
                  <img src="~@/assets/img/icon-marker.svg" alt="marker">
									<span>{{ order.pickupAddress }}</span>
								</div>
								<div class="form-group last-form-group">
									<label for="pickup-contact-phone-field" class="label-required">Telefoonnummer contactpersoon</label>
									<input type="text" pattern="^(\d{10})$" id="pickup-contact-phone-field" class="form-control" placeholder="Telefoonnummer..." v-model="order.pickupPhone" required>
								</div>
							</div>
						</div>

            <!-- delivery address -->

						<div class="column inner-column split-column">
							<div class="content-container-inner">
								<h4 class="step third-subtitle">Afleveradres</h4>
								<div class="columns-outer inner-columns-outer">
									<div class="column inner-column split-column">
										<div class="form-group">
											<label for="delivery-postal-code-field" class="label-required">Postcode</label>
											<input type="text" id="delivery-postal-code-field" class="form-control" :class="{ 'form-control-error': validAddress['delivery'] === false }" placeholder="Postcode..." v-model="order.deliveryPostalCode" @change="setAddress('delivery')" required>
										</div>
									</div>
									<div class="column inner-column split-column double-split">
										<div class="form-group first-form-group">
											<label for="delivery-house-number-field" class="label-required">Huisnr.</label>
											<input type="text" id="delivery-house-number-field" class="form-control" :class="{ 'form-control-error': validAddress['delivery'] === false }" placeholder="Nr..." v-model="order.deliveryHouseNumber" @change="setAddress('delivery')" required>
										</div>
										<div class="form-group second-form-group">
											<label for="delivery-house-number-addition-field">Toev.</label>
											<input type="text" id="delivery-house-number-addition-field" class="form-control" placeholder="Toev..." v-model="order.deliveryHouseNumberAddition" @change="setAddress('delivery')">
										</div>
									</div>
								</div>
								<div class="result" v-show="order.deliveryAddress">
                  <img src="~@/assets/img/icon-marker.svg" alt="marker">
									<span>{{ order.deliveryAddress }}</span>
								</div>
                <div class="form-group last-form-group">
									<label for="phonenumber-contact-field" class="label-required">Telefoonnummer contactpersoon</label>
									<input type="text" pattern="^(\d{10})$" id="phonenumber-contact-field" class="form-control" placeholder="Telefoonnummer..." v-model="order.deliveryPhone" required>
								</div>
							</div>
						</div>
					</div>

          <!-- alert distance -->

          <div role="alert" class="alert alert-error alert-icon fade show alert-margin-top" v-if="alertDistance">
            <div class="icon">
              <img src="~@/assets/img/icon-alert.svg" alt="alert">
            </div>
            <p>Het is niet gelukt om een route te bepalen tussen de opgegeven adressen. Neem contact op via 085 130 1832 of via de chat.</p>
          </div>

					<!-- note & agree -->

					<div class="content-container-inner">
						<h4 class="step fourth-subtitle">Rond je reservering af</h4>
						<div class="form-group">
							<label for="note-field">Opmerking voor transport</label>
							<textarea type="text" id="note-field" class="form-control" placeholder="Opmerking..." autocomplete="off" v-model="order.note"></textarea>
						</div>
						<button type="button" class="btn btn-primary-reversed btn-icon" data-toggle="modal" data-target="#couponModal">
							<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7 0.5V7.5M7 14.5V7.5M7 7.5H0H14" stroke="#25A268" stroke-width="1.7"/>
							</svg>
							Couponcode inwisselen
						</button>
						<div class="form-check special-check simple-checks">
							<input type="checkbox" id="first-agree-check" name="firstAgree" class="form-check-input" v-model="firstAgree">
							<label for="first-agree-check" class="form-check-label">
								Ik ga akkoord met de <a href="https://www.directtransport.nl/algemene-voorwaarden" target="_blank">algemene voorwaarden</a>
							</label>
						</div>
						<div class="form-check special-check simple-checks">
							<input type="checkbox" id="second-agree-check" name="secondAgree" class="form-check-input" v-model="secondAgree">
							<label for="second-agree-check" class="form-check-label">
								Hierbij verklaar ik de gegevens naar waarheid te hebben ingevuld
							</label>
						</div>
            <div class="form-check special-check simple-checks" v-if="order.customer.payByInvoice">
              <input type="checkbox" id="pay-by-invoice" name="payByInvoice" class="form-check-input" v-model="payByInvoice">
              <label for="pay-by-invoice" class="form-check-label">
                Betaling: voldoen per factuur
              </label>
            </div>
					</div>
        </div>
      </section>

      <!-- navigation -->

			<div class="navigation">
        <button type="button" class="btn btn-tertiary btn-prev" v-if="currentStep !== 0" @click="$router.push('/order/' + previousStep) && scrollToTop();">Vorige</button>
				<button type="button" class="btn btn-primary btn-next" v-if="currentStep === 0" :disabled="!order.kind || alertGeneral" @click="stepsComplete = 1; $router.push('/order/' + nextStep) && scrollToTop();">Volgende stap</button>
        <button type="button" class="btn btn-primary btn-next" v-if="currentStep === 1 && !alertSize && !alertVolume && !alertWeight && !sizeCheckShown" :disabled="itemInvalidFields" data-toggle="modal" data-target="#confirmModal">Volgende stap</button>
        <button type="button" class="btn btn-primary btn-next" v-if="currentStep === 1 && !alertSize && !alertVolume && !alertWeight && sizeCheckShown" @click="stepsComplete = 2; sizeCheckShown = true; $router.push('/order/' + nextStep) && scrollToTop();" :disabled="itemInvalidFields">Volgende stap</button>
        <button type="button" class="btn btn-primary btn-next" v-if="currentStep === 2" @click="stepsComplete = 3; $router.push('/order/' + nextStep) && scrollToTop();" :disabled="!order.date || !order.pickupTime || !order.deliveryTime">Volgende stap</button>
        <button type="button" class="btn btn-primary btn-next" v-if="currentStep === 3 && !(order.deliveryType === 'custom' && ((order.pickupFloor === 'fifth floor and up' && order.pickupElevator === false) || (order.deliveryFloor === 'fifth floor and up' && order.deliveryElevator === false)))" @click="stepsComplete = 4; $router.push('/order/' + nextStep) && scrollToTop();" :disabled="order.help === null">Volgende stap</button>
				<button type="button" class="btn btn-primary btn-next" v-if="currentStep === 4" @click="addOrder" :disabled="!customerValid || !order.pickupAddress || !order.deliveryAddress || order.pickupAddress === order.deliveryAddress || !firstAgree || !secondAgree || (createAccount && order.customer.password !== order.customer.passwordCheck) || loading || alertDistance">{{ payByInvoice ? 'Afronden' : 'Betalen' }}</button>
			</div>

			<!-- discount -->

			<div class="nudge nudge-discount d-none d-block d-lg-none" v-if="discountActive">
				<h3 class="nudge-title">Ontvang 10% korting</h3>
        <span class="discount-text">Van €{{ formatPrice(this.order.priceTotal)}} voor <i>€{{ formatPrice(this.order.priceTotal - (this.order.priceTotal * 0.1)) }}</i></span>
				<div class="buttons-side-to-side">
					<button class="btn btn-tertiary" @click="discountActive = false">Afwijzen</button>
					<button class="btn btn-primary" @click="discountActive = false; order.discountApplied = true">Ontvang 10% korting</button>
				</div>
			</div>

			<!-- savings -->

			<div class="nudge nudge-savings" v-show="currentStep === 3" v-if="showSavings">
				<button class="close-btn" @click="showSavings = false"></button>
				<h3 class="nudge-title">Dit bespaar je met dit transport</h3>
				<span class="savings-text">Ten opzichte van het huren van een busje</span>
				<span class="saving cost">&euro; {{ priceSavings }}</span>
				<span class="saving time">{{ 2 + Math.round((order.distance / 25) * 0.5) }} uur</span>
			</div>

    </main>

    <!-- thank you -->

    <main id="thank-you" v-if="currentStep === 5">
			<div class="content-inner">
				<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="40" cy="40" r="40" fill="#25A268"/>
				<path d="M20 37.3134L35.1852 52L60 28" stroke="white" stroke-width="5"/>
				</svg>
        <template v-if="!mailSuccess">
          <h2 class="end-title">Je transport is succesvol betaald en zal worden ingepland</h2>
          <p>Je ontvangt binnen 2 minuten een bevestiging via de e-mail. We zullen je op de hoogte houden over de vorderingen van je transport.</p>
          <router-link to="/login" tag="a" class="btn btn-primary btn-go-to-dashboard">Naar mijn dashboard</router-link>
        </template>
        <template v-if="mailSuccess">
          <h2 class="end-title">We sturen je het kostenoverzicht door via de mail</h2>
          <p>Je ontvangt binnen 2 minuten het kostenoverzicht via de e-mail. We hopen je binnenkort te kunnen helpen met je transport.</p>
        </template>
			</div>
    </main>

  </div>

  <!-- modal: check measurements -->

	<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModal" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Kloppen de afmetingen?</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M17 1L9 9M1 17L9 9M9 9L1 1L17 17" stroke="#B8BBC2"/>
						</svg>
					</button>
				</div>
				<div class="modal-body">
					<p>De afmetingen van de voorgedefiniëerde items zijn voorbeelden. Kloppen alle afmetingen? Zo niet, pas de afmetingen aan.</p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-outline" data-dismiss="modal" @click="sizeCheckShown = true">Aanpassen</button>
					<button type="button" class="btn btn-primary" data-dismiss="modal" @click="stepsComplete = 2; sizeCheckShown = true; $router.push('/order/' + nextStep) && scrollToTop();">Doorgaan</button>
				</div>
			</div>
		</div>
	</div>

  <!-- modal: send quotation -->

	<div class="modal fade" id="costOverviewModal" tabindex="-1" role="dialog" aria-labelledby="confirmModal" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Mail mij het kostenoverzicht</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M17 1L9 9M1 17L9 9M9 9L1 1L17 17" stroke="#B8BBC2"/>
						</svg>
					</button>
				</div>
				<div class="modal-body">
					<p>Wij mailen je het overzicht van de kosten. Op deze manier kun je later je transport reserveren.</p>
					<div class="columns-outer inner-columns-outer">
						<div class="column inner-column split-column">
							<div class="form-group">
								<label for="company-name2-field">Bedrijfsnaam</label>
								<input type="text" id="company-name2-field" class="form-control" placeholder="Bedrijfsnaam..." v-model="mail.companyName">
							</div>
						</div>
						<div class="column inner-column split-column">
							<div class="form-group">
								<label for="first-name2-field" class="label-required">Voornaam</label>
								<input type="text" id="first-name2-field" class="form-control" placeholder="Voornaam..." v-model="mail.firstName" required>
							</div>
						</div>
					</div>
					<div class="columns-outer inner-columns-outer">
						<div class="column inner-column split-column">
							<div class="form-group">
								<label for="last-name2-field" class="label-required">Achternaam</label>
								<input type="text" id="last-name2-field" class="form-control" placeholder="Achternaam..." v-model="mail.lastName" required>
							</div>
						</div>
						<div class="column inner-column split-column">
							<div class="form-group">
								<label for="email2-field" class="label-required">E-mailadres</label>
								<input type="text" id="email2-field" class="form-control" placeholder="E-mailadres..." v-model="mail.email" required>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<div class="form-check special-check">
						<input type="checkbox" id="confirm-field" name="confirm-firm" class="form-check-input" v-model="mail.confirm">
						<label for="confirm-field" class="form-check-label">Ik wil mijn reservering verder afronden</label>
					</div>
					<button type="button" class="btn btn-primary" data-dismiss="modal" @click="sendMail" :disabled="!mail.firstName || !mail.lastName || !mail.email">Versturen</button>
				</div>
			</div>
		</div>
	</div>

  <!-- modal: coupon -->

	<div class="modal fade" id="couponModal" tabindex="-1" role="dialog" aria-labelledby="couponModal" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Couponcode inwisselen</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M17 1L9 9M1 17L9 9M9 9L1 1L17 17" stroke="#B8BBC2"/>
						</svg>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<input type="text" id="coupon-field" class="form-control" :class="{ 'form-control-error': validCoupon === false }" placeholder="Vul je couponcode in..." @keyup="checkCoupon" v-model="couponCode" autocomplete="off" required>
					</div>
					<div role="alert" class="alert alert-error alert-icon fade show alert-margin-top" v-if="validCoupon === false">
						<div class="icon">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.46756 12.9528C9.62516 12.8475 9.81044 12.7913 9.99998 12.7913C10.2541 12.7913 10.4979 12.8923 10.6776 13.072C10.8573 13.2517 10.9583 13.4955 10.9583 13.7497C10.9583 13.9392 10.9021 14.1245 10.7968 14.2821C10.6915 14.4397 10.5418 14.5625 10.3667 14.6351C10.1916 14.7076 9.99892 14.7266 9.81302 14.6896C9.62712 14.6526 9.45636 14.5613 9.32234 14.4273C9.18831 14.2933 9.09704 14.1225 9.06006 13.9366C9.02308 13.7507 9.04206 13.5581 9.1146 13.3829C9.18713 13.2078 9.30996 13.0582 9.46756 12.9528Z" fill="#FF2B44"></path><path d="M10.8333 5.29134H9.16667V11.1247H10.8333V5.29134Z" fill="#FF2B44"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 1.38332C5.24114 1.38332 1.38332 5.24114 1.38332 10C1.38332 14.7589 5.24114 18.6167 10 18.6167C14.7589 18.6167 18.6167 14.7589 18.6167 10C18.6167 5.24114 14.7589 1.38332 10 1.38332Z" fill="#FF2B44"></path></svg>
						</div>
						<p>Je ingevoerde code is ongeldig</p>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-primary" data-dismiss="modal" @click="order.coupon = coupon" :disabled="!validCoupon || loading">Inwisselen</button>
				</div>
			</div>
		</div>
	</div>

  <!-- modal: exit intent -->

	<div class="modal hide fade" id="exitIntentModal" ref="exitModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<h3 class="nudge-title">Wacht nog even...</h3>
				<span class="discount-text">Vul je e-mailadres in en <span>ontvang &euro; 5,- korting</span> op je transport.</span>
				<div class="form-group">
					<input type="email" id="email-exit-field" class="form-control" placeholder="Vul hier je e-mailadres in..." v-model="emailExit">
				</div>
				<div class="form-check special-check">
					<input type="checkbox" id="agree-exit-check" name="agreeExit" class="form-check-input" v-model="agreeExit" @click="agreeExit = !agreeExit">
					<label for="agree-exit-check" class="form-check-label">
						Ik ga akkoord met het <a href="https://www.directtransport.nl/privacy-beleid/" target="_blank">privacy statement</a> en het ontvangen van deze en toekomstige promoties
					</label>
				</div>
				<div class="buttons-side-to-side">
					<button class="btn btn-tertiary" aria-label="Close" @click="removeExitModal">Afwijzen</button>
					<button class="btn btn-primary" @click="addCoupon" :disabled="!agreeExit || !emailExitValid">Ontvang &euro; 5,- korting</button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

import { formatCustomTimePeriod } from '@/utils/format';
import VueCal from 'vue-cal';
import 'vue-cal/dist/i18n/nl.js'
import 'vue-cal/dist/vuecal.css';
import '@/assets/js/itemTypes';
import itemTypes from '@/assets/js/itemTypes';

export default {
  name: 'Order',
  components: {
    VueCal
  },
  props: ['user'],
  data () {
    return {
      loading: false,
      steps: [
        'soort-transport',
        'je-transport',
        'datum-en-tijd',
        'extra-diensten',
        'reserveren',
        'bedankt'
      ],
      stepsComplete: 0,
      showCardDetails: {
        0: false,
        1: false,
        2: false,
        3: false
      },
      showMobileOverview: true,
      isHoveringInfoIcon: {
        1: false,
        2: false,
        3: false,
        4: false
      },
      toggleDropdown: {},
      flash: {
        'items': false
      },
      sizeCheckShown: false,
      alertVolume: false,
      itemMaxSize: {
        'length': 400,
        'width': 200,
        'height': 220
      },
      itemTypes: itemTypes,
      itemDummy: {
        'type': '',
        'typeLabel': '',
        'typeOther': '',
        'length': '',
        'lengthInt': 0,
        'lengthError': false,
        'width': '',
        'widthInt': 0,
        'widthError': false,
        'height': '',
        'heightInt': 0,
        'heightError': false,
        'weightClass': '0-20',
        'amount': 1
      },
      priceItems: 0,
      priceTravel: 0,
      order: {
        'kind': '',
        'date': null,
        'dateSaturday': null,
        'dateSunday': null,
        'pickupTime': '',
        'pickupTimeCustom': '08:00',
        'deliveryTime': '',
        'deliveryTimeCustom': '16:00',
        'deliveryType': '',
        'pickupBuildingType': '',
        'pickupFloor': '',
        'pickupElevator': null,
        'deliveryBuildingType': '',
        'deliveryFloor': '',
        'deliveryElevator': null,
        'help': null,
        'pickupPostalCode': '',
        'pickupHouseNumber': '',
        'pickupHouseNumberAddition': '',
        'pickupStreet': '',
        'pickupCity': '',
        'pickupAddress': '',
        'pickupPhone': '',
        'deliveryPostalCode': '',
        'deliveryHouseNumber': '',
        'deliveryHouseNumberAddition': '',
        'deliveryStreet': '',
        'deliveryCity': '',
        'deliveryAddress': '',
        'deliveryPhone': '',
        'priceTravel': 0,
        'priceItems': 0,
        'priceDate': 0,
        'priceTime': 0,
        'priceLocation': 0,
        'priceExtra': 0,
        'priceCoupon': 0,
        'priceDiscount': 0,
        'priceSubtotal': 0,
        'priceVAT': 0,
        'priceTotal': 0,
        'discountApplied': false,
        'payByInvoice': false,
        'transportType': '',
        'distance': 0,
        'note': '',
        items: [],
        customer: {
          'id': '',
          'type': 'private',
          'companyName': '',
          'coc': '',
          'firstName': '',
          'lastName': '',
          'email': '',
          'phone': '',
          'password': '',
          'passwordCheck': '',
          'payByInvoice': false
        },
        coupon: {
          'id': '',
          'code': '',
          'discount': ''
        }
      },
      login: {
        'user': '',
        'pass': '',
        'error': false
      },
      mail: {
        'companyName': '',
        'firstName': '',
        'lastName': '',
        'email': '',
        'confirm': false,
      },
      coupon: {},
      mailSuccess: false,
      validDeliveryType: false,
      validPickup: false,
      validDelivery: false,
      validAddress: {
        'pickup': null,
        'delivery': null
      },
      couponCode: '',
      validCoupon: null,
      firstAgree: false,
      secondAgree: false,
      payByInvoice: false,
      createAccount: false,
      discountActive: true,
      showSavings: true,
      showTimeRemaining: true,
      showAccountOptions: true,
      timeRemaining: '',
      showExitIntent: false,
      emailExit: '',
      emailExitValid: false,
      agreeExit: false,
      alertDistance: false,
      alertGeneral: true
    }
  },
  methods: {
    formatCustomTimePeriod,
    scrollToTop () {

      setTimeout(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 500);

      return true;
    },
    scrollIntoView(elementId) {

      setTimeout(function () {
        window.scrollTo({ top: document.getElementById(elementId) !== null ? document.getElementById(elementId).offsetTop : 0, behavior: 'smooth' });
      }, 500);

      return true;
    },
    formatSize(index, type) {
      const size = parseInt(this.order.items[index][type]);

      if (isNaN(size)) {
        this.order.items[index][type + 'Int'] = 0;
        this.order.items[index][type] = '';
      } else {
        this.order.items[index][type + 'Int'] = size;
        this.order.items[index][type] = size.toString() + ' cm';
        this.order.items[index][type + 'Error'] = size > this.itemMaxSize[type];
      }
    },
    setItem(item, itemType) {
      item.type = itemType.value;
      item.typeLabel = itemType.label;
      item.length = itemType.length.toString() + ' cm';
      item.lengthInt = itemType.length;
      item.width = itemType.width.toString() + ' cm';
      item.widthInt = itemType.width;
      item.height = itemType.height.toString() + ' cm';
      item.heightInt = itemType.height;
      item.weightClass = itemType.weightClass;
    },
    addItem () {
      this.order.items.push(JSON.parse(JSON.stringify(this.itemDummy)));
    },
    dateClicked(event) {

      if (event.path[2].classList.contains('vuecal__cell--disabled')) {
        this.order.date = null;
        this.order.dateSaturday = null;
        this.order.dateSunday = null;
      }
    },
    dateSelected(event) {

      if (event) {
        this.order.date = event.getFullYear() + '-' + (event.getMonth() + 1).toString().padStart(2, '0') + '-' + event.getDate().toString().padStart(2, '0');
        this.order.dateSaturday = new Date(this.order.date).format('d') === '6';
        this.order.dateSunday = new Date(this.order.date).format('d') === '7';
        this.order.pickupTime = '';
        this.order.deliveryTime = '';
        this.scrollIntoView('pickup-time');
      } else {
        this.order.date = null;
        this.order.dateSaturday = null;
        this.order.dateSunday = null;
      }
    },
    setAddress(type) {
      let validAddress = false;

      if (this.order[type + 'PostalCode']
          && this.order[type + 'HouseNumber']
      ) {
        this.order[type + 'PostalCode'] = this.order[type + 'PostalCode'].toUpperCase().replace(/\s/g, '');

        fetch('/api/orders/validate-postal-code/' + this.order[type + 'PostalCode'] + '/' + this.order[type + 'HouseNumber'])
            .then(response => response.ok ? response.json() : [])
            .then(data => {

              if (data['valid']) {
                validAddress = true;
                this.validAddress[type] = true;

                this.order[type + 'Street'] = data['street'];
                this.order[type + 'City'] = data['city'];
                this.order[type + 'Address'] =
                    this.order[type + 'Street']
                    + ' '
                    + this.order[type + 'HouseNumber']
                    + this.order[type + 'HouseNumberAddition']
                    + ' in ' + this.order[type + 'City'];

                this.calculatePriceTravel(
                    encodeURIComponent(
                    this.order.pickupStreet + ' ' + this.order.pickupHouseNumber + ' ' + this.order.pickupCity),
                    encodeURIComponent(
                        this.order.deliveryStreet + ' ' + this.order.deliveryHouseNumber + ' ' + this.order.deliveryCity)
                );
              }
            })
      }

      if (!validAddress) {
        this.order[type + 'Street'] = '';
        this.order[type + 'City'] = '';
        this.order[type + 'Address'] = '';
        this.validAddress[type] = false;
      }
    },
    calculatePriceTravel(origin, destination) {

      if (origin && destination) {

        fetch('/api/orders/calculate-distance/' + origin + '/' + destination)
            .then(response => response.ok ? response.json() : {})
            .then(data => {

              if (data.error) {
                this.alertDistance = true;
              } else {
                this.alertDistance = false;
                this.order.distance = data.distance;
                this.priceTravel =
                    this.order.distance > 66 ? 19.8 + ((this.order.distance - 66) * 0.6) : this.order.distance * 0.75;

                if (this.order.distance >= 150) {
                  this.order.pickupTime = 'all day';
                  this.order.deliveryTime = 'all day';
                }
              }
            });
      }
    },
    calculatePrice(outputSubTotal = false) {
      let itemsPrice = 0,
          itemsCount = 0,
          volume = 0,
          priceSubtotal = 0;

      for (const item of this.order.items) {

        if (
            item.lengthInt > 0
            && item.widthInt > 0
            && item.heightInt > 0
        ) {
          volume += ((item.lengthInt / 100) * (item.widthInt / 100) * (item.heightInt / 100)) * item.amount;
          itemsCount += item.amount;
        }
      }

      this.alertVolume = volume > 6;

      if (volume > 0) {
        itemsPrice += (itemsCount - 1) * 2.5;

        if (volume > 2 && volume <= 6) {
          itemsPrice += 7.5;
          itemsPrice += (volume - 1) * 8;

          this.order.transportType = 'van';
        } else if (volume > 0.6) {
          itemsPrice += 5;
          itemsPrice += (volume - 1) * 8;

          this.order.transportType = 'combo';
        } else {
          this.order.transportType = 'car';
        }
      }

      this.priceItems = itemsPrice;
      priceSubtotal = 42.5 + this.priceTravel + this.priceItems + this.priceDate + this.priceTime + this.priceLocation + this.priceExtra;

      if (!outputSubTotal) {
        this.order.priceSubtotal = (priceSubtotal - this.priceCoupon - this.priceDiscount) / 1.21;

        if (this.order.priceSubtotal < 0) {
          this.order.priceSubtotal = 0;
          this.order.priceVAT = 0;
          this.order.priceTotal = 0;
        } else {
          this.order.priceVAT = this.order.priceSubtotal * 0.21;
          this.order.priceTotal = this.order.priceSubtotal + this.order.priceVAT;
        }
      } else {
        return priceSubtotal;
      }
    },
    formatPrice(price) {
      return new Intl.NumberFormat('nl-NL', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(price);
    },
    setMailData () {

      if (this.order.customer.companyName) {
        this.mail.companyName = this.order.customer.companyName;
      }

      if (this.order.customer.firstName) {
        this.mail.firstName = this.order.customer.firstName;
      }

      if (this.order.customer.lastName) {
        this.mail.lastName = this.order.customer.lastName;
      }

      if (this.order.customer.email) {
        this.mail.email = this.order.customer.email;
      }
    },
    setOrderData () {
      this.order.priceTravel = (42.5 + this.priceTravel);
      this.order.priceItems = this.priceItems.toFixed(2);
      this.order.priceDate = this.priceDate;
      this.order.priceTime = this.priceTime;
      this.order.priceLocation = this.priceLocation;
      this.order.priceExtra = this.priceExtra;
      this.order.priceCoupon = this.priceCoupon.toFixed(2);
      this.order.priceDiscount = this.priceDiscount.toFixed(2);
      this.order.priceSubtotal = this.order.priceSubtotal.toFixed(2);
      this.order.priceVAT = this.order.priceVAT.toFixed(2);
      this.order.priceTotal = this.order.priceTotal.toFixed(2);
      this.order.payByInvoice = this.payByInvoice;

      this.order.customer.firstName = this.order.customer.firstName[0].toUpperCase() + this.order.customer.firstName.substring(1);
    },
    checkCoupon () {

      if (!this.loading
          && this.couponCode
      ) {
        this.loading = true;
        this.coupon = {};

        setTimeout(() => {

          fetch('/api/orders/check-coupon/' + this.couponCode)
              .then(response => response.ok ? response.json() : null)
              .then(data => {

                if (data) {
                  this.coupon = data;
                  this.validCoupon = true;
                } else {
                  this.validCoupon = false;
                }

                this.loading = false;
              })
        }, 250);
      }
    },
    sendMail () {
      this.order.customer.firstName = this.mail.firstName;
      this.order.customer.lastName = this.mail.lastName;
      this.order.customer.email = this.mail.email;

      this.setOrderData();

      fetch('/api/orders/send-quotation', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(this.order)
      })
          .then(() => {

            if (!this.mail.confirm) {
              this.mailSuccess = true;
              this.$router.push('/' + this.nextStep);
            }
          })
    },
    removeExitModal () {

      if (document.getElementById('exitIntentBackdrop') !== null) {
        document.getElementById('exitIntentModal').remove();
        document.getElementById('exitIntentBackdrop').remove();
      }
    },
    doLogin () {
      this.login.error = false;

      fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.login),
      })
        .then(response => (response.ok ? response.json() : null))
        .then(data => {

          if (data) {
            let cookieDate = new Date();

            cookieDate.setTime(cookieDate.getTime() + 86400000);
            document.cookie = 'mylox-token=' + data['token'] + '; expires=' + cookieDate.toUTCString() + '; path=/';

            this.$store.commit('setLoggedIn', true);
            this.$store.commit('setUser', data);

            if (data['profile'] === 'customer') {
              this.getCustomerData();
            }
          } else {
            this.login.error = true;
          }
        });
    },
    getCustomerData () {

      if (this.user['customerId']) {

        fetch('/api/customers/' + this.user['customerId'])
          .then(response => response.ok ? response.json() : {})
          .then(data => {

            if (data) {
              const
                hashParams = new URLSearchParams(window.location.hash.substring(1)),
                addressDefault = {
                  'pickup': !!hashParams.get('pickupDefault'),
                  'delivery': !!hashParams.get('deliveryDefault')
                };
              let customRoute = hashParams.get('origin') && hashParams.get('destination');

              // strip pickup & delivery if custom route given

              if (customRoute) {
                const addressProps = [
                  'postalCode',
                  'houseNumber',
                  'houseNumberAddition',
                  'street',
                  'city',
                  'address'
                ];

                for (const addressType of ['pickup', 'delivery']) {

                  if (!addressDefault[addressType]) {

                    for (const addressProp of addressProps) {
                      delete data[addressType + addressProp.charAt(0).toUpperCase() + addressProp.slice(1)];
                      delete data[addressType][addressProp];
                    }
                  }
                }
              }

              Object.assign(this.order, data);
              Object.assign(this.order.customer, data);

              if (!customRoute) {

                this.calculatePriceTravel(
                  encodeURIComponent(
                    this.order.pickupStreet + ' ' + this.order.pickupHouseNumber + ' ' + this.order.pickupCity),
                  encodeURIComponent(
                    this.order.deliveryStreet + ' ' + this.order.deliveryHouseNumber + ' ' + this.order.deliveryCity)
                );
              }

              this.showAccountOptions = false;
            }
          })
      }
    },
    addOrder () {
      this.loading = true;
      this.setOrderData();

      // send

      fetch('/api/orders', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.order)
      })
          .then(response => response.ok ? response.text() : null)
          .then(data => {

            if (data) {
              window.location = data;
            }

            setTimeout(() => {
              this.loading = false;
            }, 3000);
          })
    },
    addCoupon () {

      fetch('/api/orders/coupon', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ email: this.emailExit })
      })
          .then(response => response.ok)
          .then(data => {

            if (data) {
              let dc = new Date;
              dc.setFullYear(dc.getFullYear() + 1);
              document.cookie = 'dt-exit-intent=true; expires=' + dc.toUTCString() + ';';
            }

            this.removeExitModal();
          })
    }
  },
  computed: {
    alertSize () {
      let alert = false;

      for (const item of this.order.items) {

        if (
            item['lengthInt'] > this.itemMaxSize['length']
            || item['widthInt'] > this.itemMaxSize['width']
            || item['heightInt'] > this.itemMaxSize['height']
        ) {
          alert = true;
          break;
        }
      }

      return alert;
    },
    alertWeight () {
      let alert = false;

      for (const item of this.order.items) {

        if (item['weightClass'] === '100+') {
          alert = true;
          break;
        }
      }

      return alert;
    },
    itemInvalidFields () {
      let invalid = false;

      for (const item of this.order.items) {

        if (
            !item.type
            || item.lengthInt === 0
            || item.widthInt === 0
            || item.heightInt === 0
        ) {
          invalid = true;
          break;
        }
      }

      return invalid;
    },
    itemsCount () {
      let count = 0;

      for (const item of this.order.items) {
        count += item.amount;
      }

      return count;
    },
    customerValid () {
      let valid = true;

      if (this.order.customer.type === 'business'
          && (!this.order.customer.companyName || !this.order.customer.coc)
      ) {
        valid = false;
      }

      if (
          !this.order.customer.firstName
          || !this.order.customer.lastName
          || !this.order.customer.email
          || !this.order.customer.phone
          || !this.order.pickupPhone
          || !this.order.deliveryPhone
      ) {
        valid = false;
      }

      if (document.querySelectorAll('#customer input:invalid').length) {
        valid = false;
      }

      return valid;
    },
    priceDate () {
      let price = 0;

      if (this.order.date) {

        if (this.order.date === new Date().addDays(1).format('YYYY-MM-DD')) {
          price += 32.5;
        } else if (this.order.date === new Date().addDays(2).format('YYYY-MM-DD')) {
          price += 17.5;
        } else if (this.order.date === new Date().addDays(3).format('YYYY-MM-DD')) {
          price += 10;
        }

        if (this.order.dateSaturday) {
          price += 17.5;
        } else if (this.order.dateSunday) {
          price += 22.5;
        }
      }

      return price;
    },
    deliveryTimeOptions () {
      const hoursDelivery = [12, 13, 14, 15, 16, 17, 18, 19, 20];
      let timeOptions = [];

      for (const hourDelivery of hoursDelivery) {
        const minHourDelivery = parseInt(this.order.pickupTimeCustom.split(':')[0]) + 4;

        if (hourDelivery >= minHourDelivery
          || this.order.pickupTime === 'all day'
        ) {
          timeOptions.push({ value: String(hourDelivery) + ':00', label: String(hourDelivery - 4).padStart(2, '0') + ':00 - ' + String(hourDelivery) + ':00' });
        }
      }

      return timeOptions;
    },
    priceTime () {
      let price = 0;

      for (const timeOfDay of [this.order.pickupTime, this.order.deliveryTime]) {

        if (timeOfDay === 'custom') {
          price += 12.5;
        }
      }

      return price;
    },
    priceLocation () {
      let price = 0;

      if (this.order.deliveryType === 'custom') {

        for (const type of ['pickup', 'delivery']) {

          if (this.order[type + 'Elevator'] === true) {
            price += 10;
          } else {

            switch (this.order[type + 'Floor']) {
              case 'first floor':
                price += 10;
                break;
              case 'second floor':
                price += 20;
                break;
              case 'third floor':
                price += 30;
                break;
              case 'fourth floor':
                price += 40;
                break;
            }
          }
        }
      }

      return price;
    },
    priceHelp () {
      let price = 49.5;

      if (this.order.distance > 82) {
        price += (this.order.distance - 82) * 0.5;
      }

      return price;
    },
    priceExtra () {
      return this.order.help ? this.priceHelp : 0;
    },
    priceCoupon () {
      let price = 0;

      if (this.order.coupon.id) {

        if (this.order.coupon.discount.includes('%')) {
          price = (this.order.discountApplied ? this.calculatePrice(true) * 0.9 : this.calculatePrice(true)) * (parseInt(this.order.coupon.discount.slice(0, -1)) / 100);
        } else {
          price = parseInt(this.order.coupon.discount);
        }
      }

      return price;
    },
    priceDiscount () {
      return this.order.discountApplied ? this.calculatePrice(true) * 0.1 : 0;
    },
    priceSavings () {
      return Math.ceil((((this.order.transportType === 'van' ? 200 : this.order.transportType === 'combo' ? 160 : 120) + this.order.distance * 0.19) - this.order.priceTotal) / 5) * 5;
    },
    currentStep () {
      return this.steps.indexOf(this.$route.params.step);
    },
    previousStep () {
      return this.currentStep ? this.steps[this.currentStep - 1] : 0;
    },
    nextStep () {
      return this.currentStep < 4 ? this.steps[this.currentStep + 1] : 4;
    }
  },
  watch: {
    order: {
      deep: true,
      handler () {

        if (this.order.deliveryType === 'custom') {

          // show pickup

          if (this.order.deliveryType
              && !this.validDeliveryType
          ) {
            this.validDeliveryType = true;
            this.scrollIntoView('pickup');
          }

          // show delivery

          if (!this.validPickup
              && (this.order.deliveryType === 'regular'
                  || this.order.pickupFloor === 'ground floor'
                  || this.order.pickupElevator !== null)
              && !(this.order.pickupFloor === 'fifth floor and up'
                  && this.order.pickupElevator === false)
          ) {
            this.validPickup = true;
            this.scrollIntoView('delivery');
          }

          // show help

          if (!this.validDelivery
              && (this.order.deliveryType === 'regular'
                  || this.order.deliveryFloor === 'ground floor'
                  || this.order.deliveryElevator !== null)
              && !(this.order.deliveryFloor === 'fifth floor and up'
                  && this.order.deliveryElevator === false)
          ) {
            this.validDelivery = true;
            this.scrollIntoView('help');
          }
        }
      }
    },
    'order.pickupTimeCustom': {
      handler () {

        if (this.order.pickupTimeCustom > '12:00') {
          this.order.deliveryTime = 'custom';
        }

        if (this.order.deliveryTimeCustom < this.deliveryTimeOptions[0].value) {
          this.order.deliveryTimeCustom = this.deliveryTimeOptions[0].value;
        }
      }
    },
    emailExit () {
      this.emailExitValid = this.emailExit && document.querySelector('#email-exit-field:invalid') === null;
    },
    $route () {
      this.removeExitModal();
    }
  },
  created () {

    if (this.$route.path !== '/order/bedankt') {
      this.$router.push('/order/soort-transport' + window.location.hash);
      this.addItem();
    }

    this.getCustomerData();
  },
  mounted () {

    setInterval(() => {

      if (this.currentStep > 0
          && this.stepsComplete < this.currentStep
          && this.$route.path !== '/order/bedankt'
      ) {
        this.$router.push('/order/soort-transport' + window.location.hash);
      }

      this.calculatePrice();
    }, 500);

    if (window.location.hash) {
      const hashParams = new URLSearchParams(window.location.hash.substring(1));

      if (hashParams.get('origin') && hashParams.get('destination')) {
        this.calculatePriceTravel(
            'place_id:' + hashParams.get('origin'),
            'place_id:' +hashParams.get('destination'));
      }
    }

    setInterval(() => {
      const d = new Date();
      let dr = new Date(null);

      dr.setSeconds((24 * 60 * 60) - (d.getHours() * 60 * 60) - (d.getMinutes() * 60) - d.getSeconds());

      this.timeRemaining = dr.toISOString().substr(11, 8);
    }, 1000);

    // exit intent

    if (document.cookie.split('; ').find(row => row.startsWith('dt-exit-intent'))) {
      document.getElementById('exitIntentModal').remove();
    } else {

      document.addEventListener('mouseleave', (e) => {

        if (e.clientY < 0
          && this.showExitIntent === false
          && this.$refs.exitModal
          && !this.order.customer.id
        ) {
          const elBackdrop = document.createElement('div');

          elBackdrop.setAttribute('id', 'exitIntentBackdrop');
          elBackdrop.classList.add('modal-backdrop', 'fade', 'show');
          document.body.appendChild(elBackdrop);

          this.$refs.exitModal.classList.add('d-block', 'show');
          this.showExitIntent = true;
        }
      }, false);
    }
  }
}

</script>
